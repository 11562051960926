<template>
  <layout-vertical>

    <router-view />

    <template #navbar="{ toggleVerticalMenuActive }">
      <navbar :toggle-vertical-menu-active="toggleVerticalMenuActive" />
    </template>


    <tech-service-settings v-if="(userType === 'ADMIN' || userType === 'VIEWER') && ($route.name === 'tech-service-all' || $route.name === 'tech-service-view')" slot="customizer" />

  </layout-vertical>
</template>

<script>
import LayoutVertical from '@core/layouts/layout-vertical/LayoutVertical.vue'
import TechServiceSettings from '@/layouts/components/TechServiceSettings'
import Navbar from '../components/Navbar.vue'

export default {
  components: {
    LayoutVertical,
    Navbar,
    TechServiceSettings
  },
  data() {
    return {
      // showCustomizer: $themeConfig.layout.customizer,
    }
  },
  computed: {
    userType () {
      return atob(this.$store.state.user_type)
    },
  }
}
</script>
