<template>
  <div id="tech-service-sidebar"  class="main-menu menu-fixed menu-accordion menu-shadow expanded menu-light">

    <div class="menus">
      <div v-if="hasAccessToTS" v-for="menu in menus" @click="changeMenu(menu)" :class="menu_class(menu.id)">
        <fa-icon :icon="['fas', menu.icon]" />
        <span class="title">{{menu.title}}</span>
      </div>
      <div v-for="menu in pages" @click="changePage(menu)" :class="menu_class(menu.id)">
        <fa-icon :icon="['fas', menu.icon]" />
        <span class="title">{{menu.title}}</span>
      </div>
    </div>
  </div>
</template>

<script>

import {
  BButton,
} from 'bootstrap-vue'
import store from '@/store'
import techServiceModule from '@/store/tech-service/techServiceModule'
const uniqid = require('uniqid');
export default {
  name: 'tech-service-viewer-sidebar',
  components: {
    BButton
  },
  data () {
    return {
      TECH_SERVICE_STORE_MODULE_NAME: 'techServiceModule',
      activeMenuId: 1,
    }
  },
  computed: {

    activeMenu: {
      get () {
        const menuIndex = this.menus.findIndex(e => e.route === this.$route.name && e.id === this.activeMenuId)
        if (menuIndex >= 0) {
          return this.menus[menuIndex].id
        }
        else {
          const pageIndex = this.pages.findIndex(p => p.route === this.$route.name)
          if (pageIndex >= 0) {
            return this.pages[pageIndex].id
          }
        }
      },
      set (value) {
        this.activeMenuId = value
      }
    },

    add() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].add
    },

    edit() {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].edit
    },

    menus () {
      let todayStart = new Date()
      todayStart.setHours(0, 0, 0, 0)

      let todayEnd = new Date()
      todayEnd.setHours(23, 59, 59, 59)
      return [
        {
          id: 1,
          title: 'Հաստատված',
          icon: 'clipboard-list',
          route: 'tech-service-view',
          filters: {
            Opinion: true,
            startDate: todayStart,
            endDate: todayEnd,
            type: 'CarRegNo',
            search: '',
            company: null
          }
        },
        {
          id: 2,
          title: 'Անսարք ճանաչված',
          icon: 'clipboard-list',
          route: 'tech-service-view',
          filters: {
            Opinion: false,
            startDate: null,
            endDate: null,
            type: 'CarRegNo',
            search: '',
            company: null
          }
        }
      ]
    },

    pages () {
      return this.$store.state.pages.filter(e => e.route !== 'tech-service-view')
    },

    hasAccessToTS() {
      let page = this.$store.state.pages.filter(e => e.route === 'tech-service-view');
      return page.length > 0
    },

    menu_class () {
      return (id) => {
        return (this.activeMenu === id) ? 'menu active' : 'menu'
      }
    },

    pagination () {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].pagination
    },

    filters () {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].filters
    },

    temporary_id () {
      return this.$store.state[this.TECH_SERVICE_STORE_MODULE_NAME].temporary_id
    },

    user () {
      return this.$store.state.user
    },
  },
  created() {
    if (!store.hasModule(this.TECH_SERVICE_STORE_MODULE_NAME)) store.registerModule(this.TECH_SERVICE_STORE_MODULE_NAME, techServiceModule)
  },
  methods: {
    addNewService () {
      const id = uniqid()
      this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/SET_TEMPORARY_ID', id)
      this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/CHANGE_ADD_STATE', true)
      // this.$socket.emit('NEW_CHECKUP', {
      //   id: this.temporary_id,
      //   operator: this.$store.state.user,
      //   company: this.$store.state.user.company
      // })
    },
    changeMenu (menu) {
      if (this.add || this.edit) {
        this.$swal({
          title: 'Չեղարկե՞լ գրանցումը',
          text: '',
          icon: 'info',
          showCancelButton: true,
          confirmButtonText: 'Այո',
          cancelButtonText: 'Ոչ',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })
            .then(result => {
              if (result.value) {
                if (this.add) {
                  let data = {
                    id: this.temporary_id,
                    user: this.user,
                    company: this.user.company
                  }
                  // this.$socket.emit('CHECKUP_CANCELED', data)
                }
                this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/CHANGE_ADD_STATE', false)
                this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/CLOSE_EDIT_FORM')
                this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/CLOSE_COPY_FORM')
                this.activeMenu = menu.id
                if (this.$route.name !== menu.route) {
                  this.$router.push(menu.route)
                }
                this.pagination.current_page = 1
                this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/UPDATE_FILTERS', menu.filters)
                this.getCheckups()
              }
            })
      }
      else {
        this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/CLOSE_ALL_DOCS')
        this.activeMenu = menu.id
        if (this.$route.name !== menu.route) {
          this.$router.push(menu.route)
        }
        this.pagination.current_page = 1
        this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/UPDATE_FILTERS', menu.filters)
        this.getCheckups()
      }

    },
    changePage (menu) {
      if (this.add || this.edit) {
        this.$swal({
          title: 'Չեղարկե՞լ գրանցումը',
          text: '',
          icon: 'info',
          showCancelButton: true,
          confirmButtonText: 'Այո',
          cancelButtonText: 'Ոչ',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        })
            .then(result => {
              if (result.value) {
                if (this.add) {
                  let data = {
                    id: this.temporary_id,
                    user: this.user,
                    company: this.user.company
                  }
                  // this.$socket.emit('CHECKUP_CANCELED', data)
                }
                this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/CHANGE_ADD_STATE', false)
                this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/CLOSE_EDIT_FORM')
                this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/CLOSE_COPY_FORM')
                this.activeMenu = menu.id
                if (this.$route.name !== menu.route) {
                  this.$router.push(menu.route)
                }
              }
            })
      }
      else {
        this.$store.commit(this.TECH_SERVICE_STORE_MODULE_NAME + '/CLOSE_ALL_DOCS')
        this.activeMenu = menu.id
        if (this.$route.name !== menu.route) {
          this.$router.push(menu.route)
        }
      }
    },
    serialize (obj) {
      let str = []
      for (let p in obj)
        if (obj.hasOwnProperty(p)) {
          if(typeof obj[p] !== 'undefined' && obj[p] !== null && obj[p] !== '') {
            str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]))
          }
        }
      return str.join('&')
    },
    getCheckups () {
      this.$vs.loading()
      const filters = JSON.parse(JSON.stringify(this.filters))
      const {fields} = filters
      delete filters.fields
      filters.columnFilter = JSON.stringify(fields)
      const filterData = {
        current_page: this.pagination.current_page,
        ...filters
      }
      this.$store.dispatch(this.TECH_SERVICE_STORE_MODULE_NAME + '/getViewerCheckups', this.serialize(filterData))
          .then(() => {
            this.$vs.loading.close()
          })
          .catch((error) => {
            this.$root.$emit('getError', error)
            this.$vs.loading.close()
          })
    },
  }
}
</script>

<style lang="scss">
#tech-service-sidebar {
  opacity: 1;
  .add_button {
    margin: 8px 0;
    padding: 0 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    button {
      width: 100%;
      padding: 21px 0;

      &.addTSButton {
        font-size: 1.1rem;
        font-weight: bold;
      }
    }
  }
  .menus {
    margin: 10px 0;
    padding: 0 15px;

    .menu {
      border: 1px solid #eee;
      border-bottom: 0;
      padding: 21px 10px;
      color: #252c38;
      font-weight: bold;
      font-size: 1.1rem;
      display: flex;
      align-items: center;
      justify-content: start;
      cursor: pointer;

      &:last-child {
        border-bottom: 1px solid #eee;;
      }

      &.active {
        border-right: 3px solid #ff5722;
      }

      svg {
        margin-right: 0.5rem;
        font-size: 1.2rem;
      }

      span {
        &.title {
          opacity: 1;
        }
      }
    }
  }
}

.dark-layout {
  #tech-service-sidebar {
    .menus {
      .menu {
        color: #fff
      }
    }
  }
}
</style>
